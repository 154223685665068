// import { IndexPage } from 'components';
import React, { useRef, useEffect, useState, useLayoutEffect } from 'react';
import { Fade } from 'react-awesome-reveal';
import { graphql } from 'gatsby';
import Layout from 'layouts/Clean';
import {
  SEO,
  Title,
  Text,
  Button,
  AnimatedHero,
  SimpleBlock,
  SplitTextBlock,
  TShapedBlock,
  SecretsBlock,
} from 'components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { StaticImage } from 'gatsby-plugin-image';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import videoCut from '../images/home/__hero/video_home_noaudio_x64_lowres.mp4';
import img06 from '../images/home/06-lucchesi.jpg';
import img07 from '../images/home/07-lucchesi.jpg';
import img08 from '../images/home/08-lucchesi.jpg';
import imgBackground from "../images/home/museo_testata.jpg";
import { ScrollSmoother } from 'gsap/ScrollSmoother';

gsap.registerPlugin(ScrollTrigger);
gsap.core.globals('ScrollTrigger', ScrollTrigger);

const IndexPage = ({ location }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  return (
    <>
      <Layout location={location} loading={loading}>
        <SEO
          keywords={['Vivere in tessuto', 'Lucchesi']}
          title={t("Marco Lucchesi SRL - Vivere in tessuto")}
          titleTemplate="%s"
          description={t("Un mondo che si svela passo dopo passo. Scopri le meraviglie di tessuti senza tempo nel cuore di Prato")}
          location={location}
        />
        <AnimatedHero loading={loading} setLoading={setLoading}></AnimatedHero>
        <section className="flex flex-col md:hidden">
          <div className="relative h-28 z-20">
            <div className="absolute left-1/2 right-1/2 top-16 flex h-full flex-col items-center justify-center whitespace-nowrap">
              <h1 className="text-center">
                <Title size="hero">{t('vivere')}</Title>
                <Title size="hero" color="secondary">
                  {t('in tessuto')}
                </Title>
              </h1>
            </div>
          </div>
          <div 
            className="bg-secondary w-screen h-[50vh]" 
            style={{ backgroundImage: `url(${imgBackground})`, backgroundSize: `100% auto`, backgroundRepeat: `no-repeat`, backgroundColor: `#fff` }} 
          >
            <video className="h-full object-cover"
              id="videoElement"        
              muted
              autoPlay={"autoplay"}
              preLoad="auto"
              loop
              playsInline
            >
              <source src={videoCut} type="video/mp4" className="h-full" />
            </video>
        </div>
        
        </section>
        
        {/* <section className="">
          <SplitTextBlock
            firstText={t('un mondo che si svela')}
            secondText={t('passo dopo passo')}
            image={img06}></SplitTextBlock>
        </section> */}
        <section className="mt-24 md:mt-0 mb-24">
          <SimpleBlock
            text={
              <Trans>
                UN MACROCOSMO <br />
                FATTO DI CULTURA, PASSIONE,
                <br />
                INNOVAZIONE, ARCHEOLOGIA
                <br />
                INDUSTRIALE, IMPEGNO, TECNICA E<br />
                RAFFINATEZZA
              </Trans>
            }
            image={img06}></SimpleBlock>
        </section>

        <section className="my-16">
          <TShapedBlock
            title={t('Passato e futuro che sfuggono alle regole del tempo')}
            image={img08}
            firstDesc={t(
              'I tempi sono cambiati, ma Lucchesi è ancora lì, nella stessa sede fondata da Guido Lucchesi, che ha accompagnato tutte le evoluzioni  della fabbrica e della moda dall’inizio del 900.',
            )}
            secondDesc={t(
              'Questo è il nostro segreto: una storia di innovazione che si rinnova di generazione in generazione, lasciando da oltre un secolo la nostra impronta sulle migliori collezioni di tutto il mondo.',
            )}
            ctaHref={'/azienda'}
            cta={t("scopri l'azienda")}></TShapedBlock>
        </section>
        <section className="my-32 md:my-16">
          <SecretsBlock />
        </section>
        <section className="h-[80vh] w-screen relative flex flex-row items-center justify-center">
          <div className="flex flex-row items-center justify-center z-20 mx-64 uppercase text-center w-2/3">
            <Title color="white">
              <Trans>Un intreccio di competenza, creatività e passione</Trans>
            </Title>
          </div>
          <div className="absolute w-full h-full">
            <StaticImage
              className="w-full h-full z-10"
              objectFit="cover"
              alt=""
              src="../images/home/12-lucchesi.jpg"></StaticImage>
          </div>
        </section>
        <section className="flex flex-col md:flex-row bg-secondary py-32">
          <div className="w-2/5 md:w-1/5 self-end my-2 md:my-0">
            <StaticImage
              className="w-full h-full z-10"
              objectFit="contain"
              alt=""
              src="../images/home/13-lucchesi.jpg"></StaticImage>
          </div>
          <div className="md:w-3/5 mx-16 my-2 md:my-0">
            <Title>
              <Trans>INNOVAZIONE</Trans>
            </Title>
            <StaticImage
              className="w-full h-full z-10"
              objectFit="contain"
              alt=""
              src="../images/home/14-lucchesi.jpg"></StaticImage>
          </div>
          <div className="w-1/3 md:w-1/5 self-start my-2 md:my-0">
            <StaticImage
              className="w-full h-full z-10"
              objectFit="contain"
              alt=""
              src="../images/home/15-lucchesi.jpg"></StaticImage>
          </div>
        </section>
        <section className="flex flex-row bg-secondary">
          <div className="hidden md:flex w-1/5 self-end"></div>
          <div className="w-full md:w-4/5 md:ml-16 bg-white p-12 md:p-16">
            <Trans>
              <Title lower>
                Lucchesi è un <font className="text-secondary">luogo di memoria</font>, formazione e
                <font className="text-secondary"> produzione</font>.
              </Title>
            </Trans>
          </div>
        </section>
        <section className="flex md:my-40 md:h-screen justify-center items-center">
          <StaticImage
            className="object-cover"
            src="../images/home/16-lucchesi.jpg"
            alt=""></StaticImage>
        </section>
      </Layout>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
